.navbar {
  background: $midnight-blue;
  overflow: hidden;
  z-index: 10;

  &__container {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  
  @media screen and (min-width: 992px) {
    height: rem-calc(98);
  }
  
  .navbar-brand {
    margin-right: rem-calc(74);
  }

  .navbar-nav {
    .nav-link {
      color: $colors-basic-light;
      font-size: rem-calc(18);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: rem-calc(-0.43);
      min-width: rem-calc(120);
      text-align: center;
      text-decoration: none;
      
      @media screen and (max-width: 1300px) {
        font-size: rem-calc(16);
        font-weight: 500;
        line-height: rem-calc(17);
      }

      &.active {
        color: $brand;
      }

      &:hover {
        color: transparentize($brand, .3);
      }
    }
  }
}