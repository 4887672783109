@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-gray {
  color: $brownish-grey;
}

.text-dark-blue {
  color: $midnight-blue;
}

.text-dimmed {
  color: $cloudy-blue;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: rem-calc(-0.52);
}

.text-light-blue {
  color: $aqua-blue;
}

.text-yellow {
  color: $brand;
}

.text-white {
  color: $colors-basic-light;
}

.text-semibold {
  font-weight: 600;
}

.text-thick {
  font-weight: normal;
}

.text-regular {
  font-weight: normal;
}