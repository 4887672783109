.hero {
  position: relative;
}

.oval {
  background-image: linear-gradient(to right, $brand 1%, $sun-yellow 103%, #f5ce25 103%, #e5ca34 103%, #cac34d 103%, #a4ba6f 103%, #73af9b 103%, #39a1d0 103%, #2c9edc 103%, #329fd6 103%, #41a0c6 103%, #5ba2ab 103%, #80a685 103%, #aeaa54 103%, #e6af1a 103%, #f0b010 103%);
  border-radius: 50%;
  height: rem-calc(780);
  left: rem-calc(-40);
  position: relative;
  top: rem-calc(-40);
  width: rem-calc(780);
  z-index: 0;

  @media screen and (max-width: 992px) {
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 1100px) {
    height: rem-calc(500);
    width: rem-calc(500);
  }

  @media screen and (min-width: 1101px) and (max-width: 1300px) {
    height: rem-calc(580);
    width: rem-calc(580);
  }
  
  @media screen and (min-width: 1301px) and (max-width: 1500px) {
    height: rem-calc(680);
    width: rem-calc(680);
  }
}
