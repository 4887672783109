.appData {
  border-bottom: rem-calc(1) solid $light-pink;
  border-top: rem-calc(1) solid $light-pink;
  margin-top: rem-calc(40);
  padding: rem-calc(60 20 80);

  &.no-border {
    border: 0;
  }

  &__description {
    color: $brownish-grey;
    display: block;
    font-size: rem-calc(18);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: rem-calc(-0.63);
    max-width: rem-calc(170);
    margin: auto;
    margin-top: rem-calc(28);
  }

  &__mainText {
    color: $aqua-blue;
    font-size: rem-calc(42);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: rem-calc(-1.47);
    max-width: rem-calc(653);

    @media screen and (max-width: 1300px) {
      font-size: rem-calc(35);
      text-align: center;
    }
  }

  &__rating {
    margin-top: rem-calc(28);
  }

  &__value {
    color: $midnight-blue;
    font-size: rem-calc(70);
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: rem-calc(-2.45);
    margin-top: rem-calc(40);

    @media screen and (max-width: 1300px) {
      font-size: rem-calc(60);
    }

    &__container {
      display: inline-block;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  &__values {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }
  }
}
