@import "~react-calendar/dist/Calendar.css";

.barest-calendar {
  background-color: $colors-basic-light;
  border-radius: 26px;
  border: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.21);
  font-family: 'Poppins', sans-serif !important;
  padding: rem-calc(0 10);
  position: relative;

  .date-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(0 6);

    &__fullDate {
      background-color: $pale-grey;
      border: solid 1px $aqua-blue;
      color: $midnight-blue;
      border-radius: rem-calc(6);
      display: block;
      font-size: rem-calc(11);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      padding: rem-calc(2 8);
      
      @media screen and (max-width: 540px) {
        font-size: rem-calc(9);
      }
    }
    
    &__month {
      color: $midnight-blue;
      font-size: rem-calc(22);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: capitalize;

      @media screen and (max-width: 540px) {
        font-size: rem-calc(16);
      }
    }
  }
  
  .react-calendar__navigation__label {
    border-bottom: rem-calc(1) solid $light-pink;
    background: white !important;
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
  }

  .react-calendar__navigation__arrow {
    background-color: transparent !important;
    height: rem-calc(60);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: rem-calc(60);

    img {
      width: 100%;
    }

      &.react-calendar__navigation__next-button {
        right: rem-calc(-30); 
      }
      
      &.react-calendar__navigation__prev-button {
        left: rem-calc(-30);    
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      color: $brownish-grey;
      font-size: rem-calc(11);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;

      abbr {
        text-decoration: none;
      }
    }

    .react-calendar__tile {
      color: $midnight-blue;
      height: rem-calc(50);
      width: rem-calc(50);

      @media screen and (max-width: 540px) {
        height: rem-calc(40);
        width: rem-calc(40);
      }
      
      &--active {
        background: $brand;
        border-radius: 50%;
        color: white !important;
        font-weight: 700;
        
        &:focus {
          background: $brand;
        } 
      }
      
      &--now {
        border-radius: 50%;
        background: transparentize($aqua-blue, .8);
      }

      &:disabled {
        background: transparentize($light-pink, .5);
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: $aqua-blue;
    }
}
