.card {
  background-color: $colors-basic-light;
  border-radius: rem-calc(22);
  box-shadow: rem-calc(0 2 8 0) rgba(0, 0, 0, 0.29);
  margin: auto;
  margin-top: rem-calc(26);
  padding: rem-calc(36 30 47 30);
  width: rem-calc(410);
  min-height: 34.5rem;
  height: auto;

  &__stripe-logo {
    background: url("../../images/files/stripe.svg");
    background-size: cover;
    display: block;
    height: rem-calc(23);
    width: rem-calc(55);
  }

  &--responsive {
    @media screen and (max-width: 1280px) {
      width: 100%;
    }
  }

  &__advice {
    color: $brownish-grey;
    font-size: rem-calc(20);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: rem-calc(-0.7);

    &--small {
      font-size: rem-calc(14);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: rem-calc(-0.56);
    }
  }

  &__badge {
    border-radius: rem-calc(16.5);
    font-size: rem-calc(11);
    display: inline-block;
    height: rem-calc(33);
    line-height: rem-calc(22);
    padding: rem-calc(5 15);
    text-align: center;

    &.info {
      background-color: $aqua-blue;
      color: $midnight-blue;
    }

    &.caveat {
      background-color: $orangish-red;
      color: $midnight-blue;
    }

    &.warning {
      background-color: $brand;
      color: $midnight-blue;
    }

    &.gray {
      background-color: $brownish-grey;
      color: $midnight-blue;
    }
  }

  &__bigValue {
    color: $midnight-blue;
    font-size: rem-calc(35);
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: rem-calc(-1.22);
    margin-bottom: 0;
  }

  &__mediumValue {
    color: $midnight-blue;
    font-size: rem-calc(28);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: rem-calc(-1.22);
    margin-bottom: 0;
  }

  &__body {
    padding: rem-calc(0 16);
    &__btn-info-custom {
      color: white;
      font-size: 1.25rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.04375rem;
    }
  }

  &__btn {
    padding: rem-calc(16 21 19 21);
    object-fit: contain;
    border-radius: rem-calc(13);

    &-bigtext {
      color: $colors-basic-light;
      font-size: rem-calc(35);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: rem-calc(-1.23);

      @media screen and (max-width: 1300px) {
        font-size: rem-calc(30);
      }
    }

    &-smalltext {
      color: $colors-basic-light;
      font-size: rem-calc(14);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: rem-calc(-0.49);
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__highlighted {
    color: $aqua-blue;
    font-size: rem-calc(18);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: rem-calc(-0.63);
  }

  &__list {
    list-style: none;

    li {
      color: $brownish-grey;
      font-size: rem-calc(12);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: rem-calc(-0.42);
      margin-bottom: rem-calc(23);

      &:before {
        content: "\2022";
        color: $midnight-blue;
        font-weight: bold;
        display: inline-block;
        width: 1rem;
        margin-left: -1rem;
      }
    }
  }

  &__smallValue {
    color: $midnight-blue;
    font-size: rem-calc(16);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: rem-calc(-1.05);
  }

  &__smallText {
    color: $brownish-grey;
    font-size: rem-calc(12);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: rem-calc(-0.42);
  }

  &__verySamllText {
    color: $brownish-grey;
    font-size: rem-calc(7);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: rem-calc(-0.42);
  }
}
