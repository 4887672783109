.form {
  &-control,
  &-select {
    color: $midnight-blue;
    max-width: rem-calc(850);
  }

  &__label {
    margin-bottom: rem-calc(20);

    &__mainText {
      color: $midnight-blue;
      display: inline-block;
      font-size: rem-calc(25);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: rem-calc(25);
      letter-spacing: rem-calc(-0.87);
    }

    &__description {
      color: brownish-grey;
      display: block;
      font-size: rem-calc(14);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: rem-calc(-0.49);
      margin-top: rem-calc(5);
    }

    &__step {
      background-color: $colors-basic-light;
      border: rem-calc(1) solid $aqua-blue;
      border-radius: 50%;
      color: $midnight-blue;
      display: inline-block;
      font-size: rem-calc(30);
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      height: rem-calc(52);
      left: rem-calc(-16);
      line-height: normal;
      letter-spacing: rem-calc(-1.05);
      margin-right: rem-calc(10);
      padding: rem-calc(3 0);
      position: absolute;
      text-align: center;
      vertical-align: middle;
      width: rem-calc(52);

      @media screen and (max-width: 992px) {
        left: 0;
      }
    }
  }
}
