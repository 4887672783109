@media (prefers-reduced-motion: no-preference) {
  .spin {
    animation: spin infinite 20s linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// scale animation
.animated-scale {
  transition: all ease-in-out .2s;
  
  &:hover {
    transform: scale(1.2);
  }
}