$brand: #fbaf02;
$colors-basic-light: #fff;
$colors-basic-dark: #000;
$midnight-blue: #080040;
$black: #353535;
$brownish-grey: #6a6a6a;
$aqua-blue: #2cc2dc;
$orangish-red: #e61d2b;
$sun-yellow: #fbcf20;
$light-pink: #ededed;
$bluey-grey: #8792b2;
$cloudy-blue: #b8bbc6;
$green: #1aaf16;
$faded-red: #e64453;
$scrims-darker-60: rgba(0, 0, 0, 0.6);
$pale-grey: #f1f6ff;
$colors-accent-blue: #007aff;
$colors-basic-destructive: #ff3b30;
$backgrounds-lightest-gray: #f3f3f3;
$colors-graybase-gray-1: #8e8e93;
$colors-graybase-gray-5: #efeff4;
$colors-graybase-gray-3: #d1d1d6;
$colors-accent-pink: #ff2d55;
$colors-graybase-gray-4: #e5e5ea;
$colors-graybase-gray-2: #c7c7cc;
$colors-accent-purple: #5856d6;
$colors-basic-adding: #48bb41;
$colors-accent-orange: #ff8c00;
$colors-accent-teal-blue: #5ac8fa;
$colors-accent-yellow: #fc0;
$scrims-darker-30: rgba(0, 0, 0, 0.3);
$colors-black: #242a37;
$blue-blue-base-700: #0c66ff;
$grey-grey-300: #f1f2f4;
$colors-grey-background: #f1f2f6;
$colors-red: #f52d56;
$blue-blue-500: #5aacff;
$colors-primary: #4252ff;
$colors-basic-adding: #4cd964;
$fill-yellow: #ffcc2a;
$material-icon-dark: rgba(0, 0, 0, 0.54);
$fill-pink: #f0a79c;
$fill-grey: #9d9d9d;
