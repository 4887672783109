.logolist {
  &__container {
    background: $light-pink;
    padding: rem-calc(60 40);
    text-align: center;
  }
  
  &__description {
    color: $midnight-blue;
    font-size: rem-calc(17);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: rem-calc(-0.6);
    margin: auto;
    margin-bottom: rem-calc(30);
    max-width: rem-calc(900);
  }
  
  &__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: rem-calc(20 0);

    img {
      margin: rem-calc(20);
    }
  }
  
  &__title {
    color: $midnight-blue;
    font-size:rem-calc(45);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: rem-calc(-1.58);
    margin-bottom: rem-calc(30);

    @media screen and (max-width: 1300px) {
      font-size: rem-calc(35);
    }
  }
}
