.bigloader {
  display: inline-block;
  left: 50%;
  position: relative;
  text-align: center;
  transform: translateX(-50%);
  top: rem-calc(30);

  img {
    width: rem-calc(170);
  }
}
