.badge {
  border-radius: rem-calc(27);
  display: inline-block;
  padding: rem-calc(12 37 10 38);
  max-width: 100%;

  &.bg-warning {
    color: $midnight-blue;
    font-size: rem-calc(18);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: rem-calc(-0.7);
    
    @media screen and (max-width: 992px) {
      font-size: rem-calc(15);
    }
   
    @media screen and (max-width: 630px) {
      font-size: rem-calc(14);
    }
  }
}

.btn {
  padding-left: rem-calc(30);
  padding-right: rem-calc(30);

  &-success {
    color: white;
    text-align: left;

    span {
      display: block;
    }
  }
  
  &-warning {
    color: white;
    font-size: rem-calc(20);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: rem-calc(-0.7);
  }
}

a {
  color: $aqua-blue;
}