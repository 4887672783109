.footer {
  color: $brownish-grey;
  padding-bottom: rem-calc(50);
  padding-top: rem-calc(50);
  
  @media screen and (max-width: 992px) {
    padding-top: rem-calc(20);
  }
  
  &__brand {
    text-align: left;

    @media screen and (max-width: 992px) {
      text-align: center;
    }

    .description {
      margin: auto;
    }
  }

  &__section {
    text-align: left;

    @media screen and (max-width: 992px) {
      text-align: center;
    }

    &__icon {
      margin-right: rem-calc(12);
    }

    &__title {
      color: $midnight-blue;
      font-size: rem-calc(20);
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: rem-calc(-0.7);
      margin: rem-calc(35 0);

      @media screen and (max-width: 1300px) {
        font-size: rem-calc(18);
      }
    }

    &__options {
      list-style: none;
      padding-left: 0;

      li {
        margin-bottom: rem-calc(22);

        a {
          color: $brownish-grey;
          font-size: rem-calc(17);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: rem-calc(-0.6);
          text-decoration: none;

          @media screen and (max-width: 1300px) {
            font-size: rem-calc(14);
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}