.feature-card {
  border-radius: rem-calc(26);
  background-color: $colors-basic-light;
  box-shadow: rem-calc(0 0 11 0) rgba(0, 0, 0, 0.08);
  margin-bottom: rem-calc(18);
  max-width: rem-calc(682);
  padding: rem-calc(12 54 24 41);
  
  @media screen and (max-width: 992px) {
    padding: rem-calc(12 34 24 21);
  }

  &__description {
    color: $brownish-grey;
    font-size: rem-calc(15);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: rem-calc(-0.56);
    margin-bottom: 0;
  }

  &__title {
    color: $brand;
    font-size: rem-calc(26);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: rem-calc(-1.05);
    margin-bottom: rem-calc(10);
    
    @media screen and (max-width: 992px) {
      font-size: rem-calc(21);
    }
  }
}